import { Nullable } from "@kitware/vtk.js/types";

const IMPLANT_PREFERENCE_COOKIE_NAME = 'Accurator_ImplantPreference';
const COOKIE_BANNER_COOKIE_NAME = 'Accurator_cookieBanner';
const THEME_COOKIE_NAME = 'Accurator_theme';
const IMPLANT_COMPANY_COOKIE_NAME = 'Accurator_Manufacturer';
const APP_TOUR_COOKIE_NAME = 'Accurator_AppTour';

type Selected = {
    manufacturer: Nullable<string>;
    model: Nullable<string>;
    diameter: Nullable<number>;
    length: Nullable<number>;
    cuff: Nullable<number>;
  };

function setCookie(cookieName: string, cookieValue: string) {
    const expirationDate = new Date;
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    document.cookie = `${cookieName}=${cookieValue}; expires=${expirationDate.toUTCString()};`;
}

function getCookie(cookieName: string): string | undefined {
    return document.cookie
        .split('; ')
        .find((row) => row.startsWith(cookieName))
        ?.split('=')[1];
}

function getCookieName(implantManufacturer: string) {
    return `${IMPLANT_COMPANY_COOKIE_NAME}_${implantManufacturer}`;
}

export function getManufacturersCookies() {
    return document.cookie
        .split('; ')
        .filter((row) => row.startsWith(IMPLANT_COMPANY_COOKIE_NAME))
        .map((value) => value.split('=')[1])
        .map((implantInfo) => implantInfo.split('/'))
        .map((implantInfo) => ({
            manufacturer: implantInfo[0],
            model: implantInfo[1],
            length: Number(implantInfo[2]),
            diameter: Number(implantInfo[3]),
            cuff: implantInfo[4].length > 0 ? Number(implantInfo[4]) : null,
        })
    );
}

export function addOrUpdateImplantPreferenceCookie(selectedImplant: Selected) {
    const manufacturer = selectedImplant.manufacturer || null;
    const model = selectedImplant.model || null;

    if (manufacturer && model) {
        const diameter = selectedImplant.diameter || '';
        const length = selectedImplant.length || '';
        const cuff = selectedImplant.cuff || '';
        setCookie(getCookieName(manufacturer.toUpperCase()), `${manufacturer}/${model}/${length}/${diameter}/${cuff}`);
        setCookie(IMPLANT_PREFERENCE_COOKIE_NAME, `${manufacturer}/${model}/${length}/${diameter}/${cuff}`);
    }
}

export function getImplantPreferenceFromCookie(): Nullable<{
    manufacturer: string,
    model: string,
    length: number,
    diameter: number,
    cuff: Nullable<number>,
}> {
    const cookieValue = getCookie(IMPLANT_PREFERENCE_COOKIE_NAME);
    
    let preference = null;
    if (cookieValue) {
        preference = cookieValue.split('/');
        if (preference && preference.length === 5) {
            return {
                manufacturer: preference[0],
                model: preference[1],
                length: Number(preference[2]),
                diameter: Number(preference[3]),
                cuff: preference[4] !== '' ? Number(preference[4]): null,
            };
        } 
    }
    return null;
}

export function addOrUpdateThemeCookie(theme: string) {
    setCookie(THEME_COOKIE_NAME, theme);
}

export function getThemeCookie(): string {
    return getCookie(THEME_COOKIE_NAME) || 'dark';
}

export function addOrUpdateCookieBannerCookie() {
    setCookie(COOKIE_BANNER_COOKIE_NAME, "false");
}

export function getCookieBannerCookie(): boolean {
    const bannerCookie = getCookie(COOKIE_BANNER_COOKIE_NAME);
    return bannerCookie === undefined || bannerCookie === "true";
}

export function addOrUpdateAppTourCookie() {
    setCookie(APP_TOUR_COOKIE_NAME, "true");
}

export function getAppTourCookie(): boolean {
    const appTourCookie = getCookie(APP_TOUR_COOKIE_NAME);
    return appTourCookie === "true";
}
